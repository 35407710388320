<template>
  <div>
    <div class="theme-display"><span>{{travel.tourAgency.name}}</span><span>{{travel.theme}}</span></div>
  <t-a-messages
    :disabled="this.$store.getters.userType == 'tourAgencyEmployee' && !ownApp"
    roomType="travel"
    :roomId="$route.params.id"
  ></t-a-messages></div>
</template>

<script>
import HandleApi from "../apiHandle";
import TAMessages from "@/components/TAMessages";

export default {
  name: "TravelTchat",
  components: {
    TAMessages,
  },
  data() {
    return {
      ownApp: false,
      travel : {}
    };
  },
  async created() {
    try {
      let res = await HandleApi.getTravelInformation(this.$route.params.id);
      this.travel = res;
      this.ownApp = res.ownApp;
    } catch (err) {
      this.$store.dispatch("unload");
      this.$store.dispatch("push_error_message", err);
    }
  },
};
</script>

<style scoped>
.theme-display {
  display: flex;
  justify-content: center;
  width: 100%;
}
.theme-display span:nth-child(1) {
  margin-right: 10px;
  font-size: 1.5rem;
  font-weight: 500;
}
.theme-display span:nth-child(2) {
  font-size: 1.5rem;
  text-decoration: underline;
}
</style>
